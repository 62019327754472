import React from 'react';
import ReactDOM from 'react-dom/client';
import { ClientApp } from './App';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ClientApp />
)

// Call the function to render the client app

