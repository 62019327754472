import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Spinner from "../Spinner/Spinner";

const LandingPage = React.lazy(() =>
  import("../LandingPage/LandingPage")
);
const SignUp = React.lazy(() => import("../SignUpForm/Signup"));

function Routing() {
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default Routing;
