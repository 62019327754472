import React from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Routing from "./containers/Router/Router";

export function ClientApp() {
  return (
    <>
      <Routing />
      <ToastContainer />
    </> 
  )
}
